import {
  Button,
  Flex,
  Group,
  Modal,
  Text,
  Title,
} from "@flpstudio/design-system";
import { useDisclosure } from "@mantine/hooks";

import {
  CLIENT_PROFILE_FORM_ID,
  ClientProfileForm,
  isProfileCompleted,
} from "@/components/organisms/ClientOnboarding/ClientProfileForm";
import { UserProfile } from "@/components/organisms/UserProfile/UserProfile";
import { useAuth } from "@/hooks/use-auth";
import { useProfilePageSearchParams } from "@/hooks/use-page-search-params";
import { Amplitude, ClientOwnProfileEvent } from "@/third-party/amplitude";

export function EditableProfile() {
  const { user } = useAuth();
  const { isModalOpen } = useProfilePageSearchParams();
  const [opened, handlers] = useDisclosure(isModalOpen("myProfile"));

  if (!user) return null;

  const cancelUpdate = () => {
    handlers.close();
    Amplitude.track(
      ClientOwnProfileEvent.name,
      ClientOwnProfileEvent.properties.profileModalClose,
    );
  };
  return (
    <>
      <Group className="mb-4 justify-between">
        <Title order={2} className="text-xl/normal">
          My profile
        </Title>
        <Button
          variant="outline"
          onClick={() => {
            handlers.open();
            Amplitude.track(
              ClientOwnProfileEvent.name,
              ClientOwnProfileEvent.properties.editProfile,
            );
          }}
        >
          Edit
        </Button>
      </Group>
      {isProfileCompleted(user) ? (
        <UserProfile
          user={user}
          className="mb-4"
          showFullProfileOnClick={false}
        />
      ) : (
        <Text className="text-[color:--mantine-color-gray-6]">
          Please add your profile information.
        </Text>
      )}
      <Modal opened={opened} onClose={cancelUpdate} title="Edit profile">
        <ClientProfileForm
          onSubmit={handlers.close}
          actionButtonGroup={
            <Flex className="-mb-6 sticky bottom-0 z-10 flex-col bg-white pb-6 lg:flex-row-reverse">
              <Button type="submit" form={CLIENT_PROFILE_FORM_ID}>
                Submit
              </Button>
              <Button variant="outline" onClick={cancelUpdate}>
                Cancel
              </Button>
            </Flex>
          }
        />
      </Modal>
    </>
  );
}
