import { Container, Divider, Paper, Title } from "@flpstudio/design-system";

import { isCompanyInfoCompleted } from "@/components/organisms/ClientOnboarding/CompanyInfoForm";
import { isITSetupCompleted } from "@/components/organisms/ClientOnboarding/ITSetupForm";
import { ITRecommendation } from "@/components/organisms/ITRecommendation/ITRecommendation";
import { useAuth } from "@/hooks/use-auth";
import { useIsClientITRecommendationEnabled } from "~/hooks/use-is-client-it-recommendation-enabled";
import { EditableCompanyInfo } from "./EditableCompanyInfo";
import { EditableITSetup } from "./EditableITSetup";
import { EditableProfile } from "./EditableProfile";
import { ITAssessment } from "./ITAssessment";
import { SoftwareInUse } from "./SoftwareInUse";

export function Profile() {
  const { user } = useAuth();

  const isClientITRecommendationEnabled = useIsClientITRecommendationEnabled();

  if (!user) return null;

  // Some users already have company and IT level data,
  // so we display them along with the IT assessment
  const displayCompany = isCompanyInfoCompleted(user);
  const displayITLevel = isITSetupCompleted(user);

  return (
    <Container component="main" className="flex flex-col gap-4">
      {isClientITRecommendationEnabled && <ITRecommendation />}
      <Title order={1} className="text-2xl/normal">
        My IT profile
      </Title>
      <Paper className="-mx-6 lg:mx-0">
        <EditableProfile />
        <Divider className="-mx-6 my-6" />
        <SoftwareInUse />
        <Divider className="-mx-6 my-6" />
        {isClientITRecommendationEnabled ? (
          <>
            <ITAssessment />
            {displayCompany && (
              <>
                <Divider className="-mx-6 my-6" />
                <EditableCompanyInfo />
              </>
            )}
            {displayITLevel && (
              <>
                <Divider className="-mx-6 my-6" />
                <EditableITSetup />
              </>
            )}
          </>
        ) : (
          <>
            <EditableCompanyInfo />
            <Divider className="-mx-6 my-6" />
            <EditableITSetup />
          </>
        )}
      </Paper>
    </Container>
  );
}
