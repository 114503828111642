import {
  Button,
  Flex,
  Group,
  Modal,
  Text,
  Title,
} from "@flpstudio/design-system";
import { useDisclosure } from "@mantine/hooks";

import {
  CompanyInfoForm,
  isCompanyInfoCompleted,
} from "@/components/organisms/ClientOnboarding/CompanyInfoForm";
import { ClientCompany } from "@/components/organisms/ClientProfile/ClientCompanyInfo";
import { useAuth } from "@/hooks/use-auth";
import { useProfilePageSearchParams } from "@/hooks/use-page-search-params";
import { Amplitude, ClientOwnProfileEvent } from "@/third-party/amplitude";

export function EditableCompanyInfo() {
  const { user } = useAuth();
  const { isModalOpen } = useProfilePageSearchParams();
  const [opened, handlers] = useDisclosure(isModalOpen("companyInfo"));

  if (!user) return null;

  const cancelUpdate = () => {
    handlers.close();
    Amplitude.track(
      ClientOwnProfileEvent.name,
      ClientOwnProfileEvent.properties.companyModalClose,
    );
  };
  return (
    <>
      <Group className="mb-4 justify-between">
        <Title order={2} className="text-xl/normal">
          Company info
        </Title>
        <Button
          variant="outline"
          onClick={() => {
            handlers.open();
            Amplitude.track(
              ClientOwnProfileEvent.name,
              ClientOwnProfileEvent.properties.editCompanyInfo,
            );
          }}
        >
          Edit
        </Button>
      </Group>
      {isCompanyInfoCompleted(user) ? (
        <ClientCompany
          size={user.clientProfile?.company?.size}
          industryId={user.clientProfile?.company?.industryId}
          workMode={user.clientProfile?.company?.workMode}
          summary={user.clientProfile?.company?.summary}
        />
      ) : (
        <Text className="text-[color:--mantine-color-gray-6]">
          This information will help us recommend IT documentation and experts.
          It will be visible only to you and any experts you directly book
          consultations with.
        </Text>
      )}
      <Modal opened={opened} onClose={cancelUpdate} title="Company info">
        <CompanyInfoForm
          actionButtonGroup={
            <Flex className="-mb-6 sticky bottom-0 z-10 flex-col bg-white pb-6 lg:flex-row-reverse">
              <Button type="submit">Submit</Button>
              <Button variant="outline" onClick={cancelUpdate}>
                Cancel
              </Button>
            </Flex>
          }
          onSubmit={handlers.close}
        />
      </Modal>
    </>
  );
}
