import { Button, Group, Text, Title } from "@flpstudio/design-system";
import { useDisclosure } from "@mantine/hooks";

import { Skeleton } from "@/components/atoms/Loading/Skeleton";
import { Drawer } from "@/components/molecules/Drawer/Drawer";
import { SoftwareGrid } from "@/components/molecules/SoftwareGrid/SoftwareGrid";
import { SoftwareForm } from "@/components/organisms/SoftwareForm/SoftwareForm";
import { useProfilePageSearchParams } from "@/hooks/use-page-search-params";
import { useClientSoftware } from "@/hooks/use-software";
import { Amplitude, ClientOwnProfileEvent } from "@/third-party/amplitude";

export function SoftwareInUse() {
  const { isModalOpen } = useProfilePageSearchParams();
  const [opened, handlers] = useDisclosure(isModalOpen("software"));
  const { data: softwares, isLoading } = useClientSoftware();

  if (isLoading) {
    return <Skeleton />;
  }

  const hasContents = !!softwares?.length;

  const onClose = () => {
    handlers.close();
  };

  return (
    <>
      <Group className="mb-4 justify-between">
        <Title order={2} className="text-xl/normal">
          Software in use
        </Title>
        <Button
          variant="outline"
          onClick={() => {
            handlers.open();
            Amplitude.track(
              ClientOwnProfileEvent.name,
              ClientOwnProfileEvent.properties.editSoftware,
            );
          }}
        >
          Edit
        </Button>
      </Group>
      {hasContents ? (
        <>
          <Text className="mb-4">
            You’ve added {softwares.length} application
            {softwares.length > 1 && "s"}.
          </Text>
          <SoftwareGrid softwares={softwares} />
        </>
      ) : (
        <Text className="text-[color:--mantine-color-gray-6]">
          This information will help experts understand your IT setup and
          provide guidance. It will be visible only to you and any experts you
          directly book consultations with.
        </Text>
      )}
      <Drawer
        opened={opened}
        onClose={onClose}
        title="Add software used by your business"
      >
        <SoftwareForm
          checked={softwares?.map((s) => s.id)}
          for="client"
          onClose={onClose}
        />
      </Drawer>
    </>
  );
}
