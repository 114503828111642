import { useQuery } from "@tanstack/react-query";

import { httpClient } from "@/api/client";

export const userQueryKey = {
  itAssessments: "itAssessments",
};

export function useUserITAssessments() {
  return useQuery({
    queryKey: [userQueryKey.itAssessments],
    queryFn: async () => {
      const { data } =
        await httpClient.paths["/v1/users/me/it-assessments"].get();
      return data;
    },
  });
}
