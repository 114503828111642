import {
  Button,
  Flex,
  Group,
  Modal,
  Text,
  Title,
} from "@flpstudio/design-system";
import { useDisclosure } from "@mantine/hooks";

import {
  ITSetupForm,
  isITSetupCompleted,
} from "@/components/organisms/ClientOnboarding/ITSetupForm";
import { ClientITSetup } from "@/components/organisms/ClientProfile/ClientITSetup";
import { useAuth } from "@/hooks/use-auth";
import { useProfilePageSearchParams } from "@/hooks/use-page-search-params";
import { Amplitude, ClientOwnProfileEvent } from "@/third-party/amplitude";

export function EditableITSetup() {
  const { user } = useAuth();
  const { isModalOpen } = useProfilePageSearchParams();
  const [opened, handlers] = useDisclosure(isModalOpen("itSetup"));

  if (!user) return null;

  const cancelUpdate = () => {
    handlers.close();
    Amplitude.track(
      ClientOwnProfileEvent.name,
      ClientOwnProfileEvent.properties.itSetupModalClose,
    );
  };

  return (
    <>
      <Group className="mb-4 justify-between">
        <Title order={2} className="text-xl/normal">
          IT setup
        </Title>
        <Button
          variant="outline"
          onClick={() => {
            handlers.open();
            Amplitude.track(
              ClientOwnProfileEvent.name,
              ClientOwnProfileEvent.properties.editItSetup,
            );
          }}
        >
          Edit
        </Button>
      </Group>
      {isITSetupCompleted(user) ? (
        <ClientITSetup
          itLevel={user?.clientProfile?.itLevel}
          productivitySuite={user?.clientProfile?.company?.productivitySuite}
          itSummary={user?.clientProfile?.company?.itSummary}
          lookingFor={user?.clientProfile?.company?.lookingFor}
        />
      ) : (
        <Text className="text-[color:--mantine-color-gray-6]">
          This information will help experts provide guidance tailored to your
          company. It will be visible only to you and any experts you directly
          book consultations with.
        </Text>
      )}
      <Modal opened={opened} onClose={cancelUpdate} title="IT setup">
        <ITSetupForm
          onSubmit={cancelUpdate}
          actionButtonGroup={
            <Flex className="-mb-6 sticky bottom-0 z-10 flex-col bg-white pb-6 lg:flex-row-reverse">
              <Button type="submit">Submit</Button>
              <Button variant="outline" onClick={cancelUpdate}>
                Cancel
              </Button>
            </Flex>
          }
        />
      </Modal>
    </>
  );
}
